<template>
  <div class="videos-list">
    <div class="videos-list-search">
      <img src="@/assets/icons/icon-search.svg" alt="Icon search" />
      <input
        type="text"
        class="videos-list-search__field"
        placeholder="Search topic"
        v-model="search"
        @change="searchVideo"
      />
    </div>
    <div class="videos-list-items">
      <div
        v-if="videos.length == 0"
        class="videos-list-item">
          <span class="no-result">Video not found</span>
      </div>
      <div
        v-else
        class="videos-list-item"
        v-for="video in videos"
        :key="video.id"
        @click="selectVideo(video)"
        :class="{ 'videos-list-item_active': isSelectedVideo(video) }"
      >
        <div class="videos-list-item__icon">
          <img src="@/assets/icons/icon-touched-18.svg" alt="" v-if="video.isSeen" />
          <img src="@/assets/icons/bold-icon play.svg" alt="" v-else />
        </div>

        <div class="videos-list-item-body">
          <div class="videos-list-item__title">{{ video.title }}</div>
          <div class="videos-list-item-info">
            <div class="videos-list-item__status" v-if="isSelectedVideo(video)">Now Playing •</div>
            <div class="videos-list-item__time">{{ video.videoDuration }} min</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingVideosList",
  props: {
    videoList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedVideo: {},
      search: '',
      videos: []
    };
  },
  beforeMount() {
    this.videos = this.videoList;
    this.selectedVideo = this.videoList.length > 0 ? this.videoList[0] : {};
    this.$emit('select-video', this.selectedVideo);
  },
  methods: {
    selectVideo(video) {
      this.selectedVideo = video;
      this.$emit('select-video', this.selectedVideo);
    },
    isSelectedVideo(video) {
      return this.selectedVideo === video;
    },
    searchVideo() {
      this.videos = this.videoList.filter((el) => el.title.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
};
</script>

<style lang="scss" scoped>
.videos-list {
  border: 1px solid #cccccc;
  background-color: #ffffff;

  &-search {
    display: flex;
    padding: 8.5px;
    border-bottom: 1px solid #cccccc;
    &__field {
      height: 100%;
      width: 100%;
      min-height: 100%;
      border: none;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      &:focus,
      &:active {
        outline: none;
        border: none;
      }
    }
  }
  &-item {
    display: flex;
    padding: 8px 16px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    &_active {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    &-body {
      margin-left: 4px;
    }
    &-header {
      display: flex;
    }
    &-info {
      display: flex;
    }
    &__title {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 4px;
    }
    &__status {
      color: #0059e8;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }
    &__time {
      color: rgba($color: #000000, $alpha: 0.7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 4px;
    }
  }
}
</style>
